import {
  split,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"

import { getIdToken } from "../utils/auth"
import { setContext } from "apollo-link-context"
import { WebSocketLink } from "@apollo/client/link/ws"
import { isBrowser } from "../utils/browser"

global.fetch = require("cross-fetch")

const createAuthLink = () =>
  setContext(async (_, { headers }) => {
    const token = await getIdToken()

    return {
      headers: {
        ...headers,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    }
  })

const createWebSocketLink = () =>
  new WebSocketLink({
    uri: `wss://later-api.herokuapp.com/v1/graphql`,
    options: {
      reconnect: true,
      connectionParams: async () => {
        const token = await getIdToken()
        return {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      },
    },
  })

let wsLink = false

const createClient = () => {
  if (isBrowser) {
    const httpLink = createHttpLink({
      uri: "https://later-api.herokuapp.com/v1/graphql",
    })

    const authLink = createAuthLink()

    wsLink = createWebSocketLink()

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        )
      },
      wsLink,
      authLink.concat(httpLink)
    )
    return new ApolloClient({
      link: link,
      cache: new InMemoryCache(),
    })
  } else {
    return {}
  }
}

export const refreshSubscription = () => {
  if (wsLink) {
    // refresh subscription whenever needed
    wsLink.subscriptionClient.close(false, false)
  }
}
export const client = createClient()
