import firebase from "firebase/app"

export const logout = () => {
  return new Promise(resolve => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        resolve()
      })
  })
}

export const checkHasuraClaim = async user => {
  const idTokenResult = await user.getIdTokenResult()
  const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"]

  if (!hasuraClaim) {
    // Check if refresh is required.
    const metadataRef = firebase
      .database()
      .ref("metadata/" + user.uid + "/refreshTime")

    metadataRef.on("value", async data => {
      if (!data.exists) return
      // Force refresh to pick up the latest custom claims changes.
      await user.getIdToken(true)
    })
  }
}

export const getIdToken = async () => {
  return firebase.auth().currentUser != null
    ? firebase.auth().currentUser.getIdToken()
    : false
}
